.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align content to the top */
  /* height: 100vh; */
  background-color: #f6f8fe;
  text-align: center;
  padding: 50px 20px; /* Add side padding for responsive layout */
  margin-top: 70px; /* 60px for the navbar + 10px for margin */
}

.box {
  background-color: white; /* White background for the box */
  padding: 60px; /* Add padding inside the box */
  border-radius: 30px; /* Rounded corners */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Light shadow for a subtle 3D effect */
  max-width: 600px; /* Limit the width of the box */
  width: 100%; /* Make it responsive */
}

.title {
  color: #000;
  font-size: 24px;
  margin-bottom: 20px;
}

.file-label {
  background-color: #f10901; /* Match button background */
  color: white; /* White text */
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 20px;
  position: relative;
}

.file-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.button {
  background-color: #014BF1;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}

.button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.loading-spinner {
  color: white;
  font-weight: bold;
}

.button:hover {
  background-color: #0039B3; /* Darker shade for hover */
}

.error {
  color: red;
  margin-top: 20px;
}

.file-list {
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
  width: 100%;
}

.file-list li {
  background-color: #e1f5fe;
  color: #000;
  padding: 10px;
  border-radius: 5px;
  margin: 5px 0;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.file-list li::before {
  content: '📄';
  margin-right: 10px;
}
